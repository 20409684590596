import { Box } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {

    const { items } = props;

    return <>
        <Box mb={1} />
        <strong>PROJECTS DEVELOPED</strong>
        <ul className="ul-remove-bullets">
            {items.slice(0, 6).map((item) => <li className="no-bullet" style={{ marginBottom: 10 }} key={item.id}>
                {'> '}<strong><u>{item.title}</u>: </strong> {item.description}<br />
                <strong>Implemented using: </strong>{item.technologies && item.technologies.join(', ')}<br />
                <strong>Company: </strong>{item.company}
                {item.links && item.links.map((link) => <span key={link.url}>
                    <br /><strong>{link.title}: </strong><a href={link.url} target="_blank" className="remove-link-styles">{link.display_link}</a>
                </span>)}
            </li>)}
            <li className="no-bullet">
                <Link to="/home/projects" className="remove-link-styles terminal-btn">
                    CLICK HERE TO VIEW ALL PROJECTS
                </Link>
            </li>
        </ul>
    </>
}
