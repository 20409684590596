import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    Divider,
    Hidden,
} from '@material-ui/core';
import PageHeader from 'src/components/PageHeader';
import Page from 'src/components/Page';
import config from 'src/config';
import firebase from '../../../utils/firebase';
import BlogPostCard from './BlogPostCard';
import { connect } from 'react-redux';
import actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

const BlogView = ({ fetchCollectionData, blog_posts }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const getCollectionData = async (key) => {
        setIsLoading(true);

        await fetchCollectionData(key);

        setIsLoading(false);
    }

    useEffect(() => {
        getCollectionData('blog_posts');
    }, []);

    return (
        <Page className={classes.root} title="Blog" isLoading={isLoading}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <PageHeader>Blog</PageHeader>
                    <Grid item md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            {blog_posts.map((blog) => <Grid item xs={12} key={blog.docId}>
                                <BlogPostCard blog={blog} />
                            </Grid>)}
                        </Grid>
                    </Grid>

                    <Grid item md={4} sm={12} xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" mt={4} width="100%" height="100%">
                            <img
                                alt="blog"
                                src="/static/images/thumbnails/blog.svg"
                                width="200"
                            />

                            {/* <Hidden smDown>
                                <img
                                    alt="blog"
                                    src="/static/images/thumbnails/blog_posts_4.svg"
                                    width="200"
                                />

                                <img
                                    alt="blog"
                                    src="/static/images/thumbnails/blog_posts_3.svg"
                                    width="200"
                                />

                                <img
                                    alt="blog"
                                    src="/static/images/thumbnails/blog_posts_2.svg"
                                    width="200"
                                />
                            </Hidden> */}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

const mapStateToProps = (state) => {
    const { blog_posts } = state.app;

    return {
        blog_posts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollectionData: (key) => {
            return dispatch(actions.fetchCollectionData(key));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogView);
