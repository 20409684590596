import React from 'react';

export default (props) => {

    const { command, onClose } = props;

    return <>
        {command}: command not found
        <br />
        Use the command &apos;site --help&apos; to view available commands. 
        <br />
        <a href="#" onClick={onClose} className="remove-link-styles">Click here</a> to close this terminal and go to my webpage.
    </>
}
