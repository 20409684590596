import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import firebase from './utils/firebase';
import config from './config';
import { connect } from 'react-redux';
import actions from './store/actions';

firebase.init();

const App = ({ fetchCollectionData }) => {
  const routing = useRoutes(routes);
  
  useEffect(() => {
    config.collections.forEach((collectionKey) => {
      fetchCollectionData(collectionKey);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchCollectionData: (key) => {
        return dispatch(actions.fetchCollectionData(key));
      },
  }
}

export default connect(null, mapDispatchToProps)(App);
