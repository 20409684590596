import React, { useEffect, useState } from 'react';
import useInterval from 'src/hooks/useInterval';

export default ({ hideLoadingText }) => {

    const loadingIntances = [
        '\\', '|', '/', '-', '|', '/', '-'
    ];
    const [instance, setIntance] = useState(0);
    
    useInterval(() => {
        setIntance(instance === 6 ? 0 : instance + 1);
    }, 200);

    return <li>
        {!hideLoadingText && 'Loading'} {loadingIntances[instance]}
    </li>
}
