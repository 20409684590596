import React, { useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import PageHeader from 'src/components/PageHeader';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    paper: {
        padding: '15px 16px',
    },
}));

const TimelineView = (props) => {
    const { 
        appStore,
        collectionKey,
        title,
        icon: Icon,
        fetchCollectionData 
    } = props;

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const collectionItems = appStore[collectionKey];

    const getCollectionData = async () => {
        setIsLoading(true);
        await fetchCollectionData(collectionKey);
        setIsLoading(false);
    }

    const formatDetail = (detail) => {
        const splitDetail = detail.split(':');

        if (splitDetail.length > 1) {
            return <>
                <strong>{splitDetail[0]}: </strong>{splitDetail[1]}
            </>;
        }

        return detail;
    }

    useEffect(() => {
        getCollectionData();
    }, [collectionKey]);

    return (
        <Page className={classes.root} title={title} isLoading={isLoading}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <PageHeader>{title}</PageHeader>

                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                            <img
                                alt={collectionKey}
                                src={`/static/images/thumbnails/${collectionKey}.svg`}
                                width="150"
                            />
                        </Box>

                        <Timeline align="alternate">    
                            {collectionItems && collectionItems.map((item) => <TimelineItem key={item.title}>
                                <TimelineOppositeContent>
                                    <Typography color="textSecondary">{item.period}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color={item.color}>
                                        <Icon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Typography variant="h4" gutterBottom>{item.title}</Typography>
                                        {item.timelineDetails && item.timelineDetails.map((detail) => <Typography key={detail}>{formatDetail(detail)}&nbsp;</Typography>)}
                                    </Paper>
                                    <Typography>&nbsp;</Typography>
                                </TimelineContent>
                            </TimelineItem>)}
                        </Timeline>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

const mapStateToProps = (state) => {  
    const appStore = state.app;
    
    return {
        appStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollectionData: (key) => {
            return dispatch(actions.fetchCollectionData(key));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineView);
