import { Box, Typography } from '@material-ui/core';
import { MyLocation, SyncAlt } from '@material-ui/icons';
import React from 'react';

export default () => {
    return <Box display="flex" flexDirection="row" mt={1}>
        <MyLocation />
        <Box mr={2} />
        
        <Typography>
            Bangalore
        </Typography>
        <Box mr={2} ml={2}>
            <SyncAlt />
        </Box>
        <Typography>
            Kozhikode
        </Typography>
    </Box>
}
