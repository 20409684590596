import React from 'react';
import {
    Box,
    Divider,
    Grid,
    Hidden,
    Typography
} from '@material-ui/core';
import ReactRotatingText from 'react-rotating-text';
import config from 'src/config';
import Email from './Email';
import Contact from './Contact';
import ContactXs from './ContactXs';
import Location from './Location';

export default ({ user }) => {

    return <>
        <Grid item md={3} xs={1}></Grid>
        <Grid item md={6} xs={10}>
            
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" color="primary">
                    {user.name}
                </Typography>
                <ReactRotatingText items={config.hello} typingInterval={100} />
            </Box>
            
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Email value={user.email_1} />
                    <Email value={user.email_2} />
                    <Location />
                </Box>
                <Hidden xsDown>
                    <img
                        alt="hello"
                        src="/static/images/thumbnails/hello.svg"
                        width="100"
                    />
                </Hidden>
            </Box>

            <Box mb={4} />
            <Hidden smUp>
                <ContactXs />
            </Hidden>
            <Hidden xsDown>
                <Contact />
            </Hidden>
            <Box mb={2} />

            <Divider />
        </Grid>
        <Grid item md={3} xs={1}></Grid>
    </>
}
