import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import app from './reducer';

const rootReducer = combineReducers({
  app
});

const configureStore = () => {
  return createStore(rootReducer, {}, applyMiddleware(thunk));
};

export default configureStore();
