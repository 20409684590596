import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Grid,
    Zoom,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 250,
        width: 250
    },
}));

export default ({ user }) => {
    const classes = useStyles();
    const [showDp, setShowDp] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowDp(true), 10)
    }, []);

    return <>
        <Grid item md={3} xs={1}></Grid>
        <Grid item md={6} xs={10}>
            <Box display="flex" justifyContent="center" mt={4}>
                <Zoom in={showDp} {...(showDp ? { timeout: 1000 } : {})}>
                    <Avatar
                        className={classes.avatar}
                        src={user.avatar}
                    />
                </Zoom>
            </Box>
        </Grid>
        <Grid item md={3} xs={1}></Grid>
    </>
}
