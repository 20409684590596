import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardHeader,
  Tooltip
} from '@material-ui/core';
import Chips from 'src/components/Chips';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProjectCard = ({ className, project, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
        <CardHeader 
          title={<Box display="flex" justifyContent="space-between">
            {project.title}
            <Tooltip title="Company/Client" placement="top">
              <Typography variant="caption">
                {project.company}
              </Typography>
            </Tooltip>
          </Box>}
          color="secondary" 
        />
        <Divider />
        <CardContent>
            <Grid container spacing={2}>
              {project.thumbnail 
                && <Grid item md={3}>
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                    <img alt={project.title} width={project.thumbnail.width} src={project.thumbnail.url} />
                  </Box>
                </Grid>}
              <Grid item md={project.thumbnail ? 9 : 12}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Typography>
                        {project.description}
                    </Typography>
                    {project.technologies && <Chips items={project.technologies} size="small" />}
                    {project.links 
                        && <Box mt={1}>
                        <Grid container spacing={2}>
                            {project.links.map((link) => <Grid item key={link.title}>
                                <Button size="small" variant="outlined" color="primary" href={link.url} target="_blank">
                                  {link.title}
                                </Button>
                            </Grid>)}
                        </Grid>
                    </Box>}
                </Box>
              </Grid>
            </Grid>
        </CardContent>
    </Card>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default ProjectCard;
