import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Description, PictureAsPdf } from '@material-ui/icons';
import { Box } from '@material-ui/core';

export default () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFile = (type) => {
    handleClose();

    const url = type === 'pdf' ? '/resume/download' : '/resume';
    window.open(url);
  }

  return (
    <div>
      <Button variant="outlined" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        View Resume
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => openFile('doc')}>
          <Description />
          <Box ml={1}>
            Google Doc
          </Box>
        </MenuItem>
        <MenuItem onClick={() => openFile('pdf')}>
          <PictureAsPdf />
          <Box ml={1}>
            Download PDF
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
