import firebase from 'firebase';
import 'firebase/firestore';

const init = () => {
    const firebaseConfig = {
        apiKey: 'AIzaSyDQ9CkSDcPKeLNhHgAt8D-xYLFcW0DYMxA',
        authDomain: 'arund-in.firebaseapp.com',
        databaseURL: 'https://arund-in.firebaseio.com',
        projectId: 'arund-in',
        storageBucket: 'arund-in.appspot.com',
        messagingSenderId: '813084912336',
        appId: '1:813084912336:web:697bc6f10615c74d068cd5',
        measurementId: 'G-RLSJL6CY9N'
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.analytics().logEvent('page_view');
}

const createNewDocument = (collectionName, data) => {
    return firebase.firestore().collection(collectionName).add(data);
}

const updateDocument = (collectionName, docName, data) => {
    return firebase.firestore().collection(collectionName).doc(docName).set(data);
}

const getDocument = async (collectionName, docName) => {
    const item = await firebase.firestore().collection(collectionName).doc(docName).get();

    if (item.exists) {
        return item.data();
    }

    return null;
}

const getCollection = async (collectionName, conditions, orderBy) => {
    let collection = firebase.firestore().collection(collectionName);

    if (conditions) {
        conditions.forEach((condition) => {
            collection = collection.where(condition[0], condition[1], condition[2]);
        });
    }

    if (orderBy) {
        orderBy.forEach((orderByItem) => {
            collection = collection.orderBy(orderByItem[0], orderByItem[1]);
        });
    }

    collection = await collection.get();

    const items = [];

    if (collection.empty) {
        return [];
    }

    for (const doc of collection.docs) {
        const docItem = doc.data();
        const docItemId = doc.id;

        // const item = formatDocItem(docItem);
        const item = docItem;

        items.push({
            ...item,
            docId: docItemId,
            id: docItemId
        });
    }

    return items;
}

export default {
    init,
    getCollection,
    createNewDocument,
    updateDocument,
    getDocument
}
