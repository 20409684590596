import { Box } from '@material-ui/core';
import React from 'react';

export default (props) => {

    const { items, header } = props;

    return <>
        <Box mb={1} />
        <strong>{header}</strong>
        <ul className="ul-remove-bullets">
            {items && items.map((item) => <li className="no-bullet" style={{ marginBottom: 10 }} key={item.id}>
                {'>'} <strong>{item.title}</strong>
                {item.link && <span>
                    {item.link.title ? ` ${item.link.title}: ` : ' - '}<a className="remove-link-styles" href={item.link.url} target="_blank">{item.link.url}</a>
                </span>}
            </li>)}
        </ul>
    </>
}
