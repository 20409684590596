import React from 'react';

export default (props) => {

    const { executeCommand } = props;

    return <li className="line">
        site --help
        <ul className="ul-remove-bullets">
            <li>Available commands:</li>
            <li className="bullet"><a onClick={() => executeCommand('exit && show gui')}>exit && show gui</a></li>
            <li className="bullet"><a onClick={() => executeCommand('wget resume')}>wget resume</a></li>
            <li className="bullet"><a onClick={() => executeCommand('echo resume')}>echo resume</a></li>
            <li className="bullet"><a onClick={() => executeCommand('ls projects')}>ls projects</a></li>
            <li className="bullet"><a onClick={() => executeCommand('ls experiences')}>ls experiences</a></li>
            <li className="bullet"><a onClick={() => executeCommand('ls skills')}>ls skills</a></li>
            <li className="bullet"><a onClick={() => executeCommand('ls interests')}>ls interests</a></li>
            <li className="bullet">
                <a onClick={() => executeCommand('ls edu')}>ls edu</a>,&nbsp;
                <a onClick={() => executeCommand('ls certifications')}>ls certifications</a>,&nbsp;
                <a onClick={() => executeCommand('ls sessions_conducted')}>ls sessions_conducted</a>,&nbsp;
                <a onClick={() => executeCommand('ls achievements')}>ls achievements</a>,&nbsp;
                <a onClick={() => executeCommand('ls extra_activities')}>ls extra_activities</a>
            </li>
        </ul>
    </li>
}
