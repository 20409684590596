import React from 'react';
import GitHub from '@material-ui/icons/GitHub';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';
import GMap from '@material-ui/icons/Room';
import Facebook from '@material-ui/icons/Facebook';
import { Box, IconButton } from '@material-ui/core';

export default () => {
    return <>
        <Box display="flex" justifyContent="space-between">
            <IconButton href="/stackoverflow" target="_blank">
                <img
                    alt="stackoverflow"
                    src="/static/images/icons/stackoverflow-colored.svg"
                    style={{ width: '28px', height: '30px' }}
                />
            </IconButton>
            <IconButton href="/linkedin" target="_blank">
                <LinkedIn fontSize="large" style={{  color: '#546E7A' }} />
            </IconButton>
            <IconButton href="/github" target="_blank">
                <GitHub fontSize="large" style={{ color: '#546E7A' }} />
            </IconButton>
            <IconButton href="/twitter" target="_blank">
                <Twitter fontSize="large" style={{  color: '#546E7A' }} />
            </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-evenly">
            <IconButton href="/gmaps" target="_blank">
                <GMap fontSize="large" style={{  color: '#546E7A' }} />
            </IconButton>
            <IconButton href="/instagram" target="_blank">
                <Instagram fontSize="large" style={{  color: '#546E7A' }} />
            </IconButton>
            <IconButton href="/fb" target="_blank">
                <Facebook fontSize="large" style={{  color: '#546E7A' }} />
            </IconButton>
        </Box>
    </>
}
