import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

export default ({ onClose }) => {
    return <div className="terminal-top-bar">
        <div>&nbsp;</div>
        <div><strong>guest@arund.in: ~</strong></div>
        <div className="terminal-close-button" onClick={onClose}>
            <CloseIcon style={{ fontSize: 15 }} />
        </div>
    </div>
}
