import React from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Hidden,
} from '@material-ui/core';
import Page from 'src/components/Page';
import CurrentWorkTimeline from './CurrentWorkTimeline';
import CurrentWorkXs from './CurrentWorkXs';
import ResumButton from './ResumButton';
import './typewriter.css';
import DP from './DP';
import Intro from './Intro';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

const user = {
    avatar: '/static/images/avatars/arun.jpg',
    name: 'Arun D',
    email_1: 'hello@ArunD.in',
    email_2: 'a.nambissan@gmail.com',
};

const BioView = () => {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Online Portfolio">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    
                    <DP user={user} />

                    <Intro user={user} />

                    <Grid item md={3} sm={12} xs={1}></Grid>
                    <Grid item xs={12}>
                        <Hidden xsDown>
                            <CurrentWorkTimeline />
                        </Hidden>
                        <Hidden smUp>
                            <CurrentWorkXs />
                        </Hidden>
                    </Grid>
                    <Grid item md={3} sm={12} xs={1}></Grid>

                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Box mt={2} />
                        <ResumButton />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default BioView;
