import { SET_COLLECTIONS } from "./actionTypes";

const initialState = {
  projects: [],
  experiences: [],
  skills: [],
  interests: [],
  edu: [],
  certifications: [],
  sessions_conducted: [],
  achievements: [],
  extra_activities: [],
  blog_posts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTIONS:
      state = {
        ...state,
        [action.payload.collectionKey]: action.payload.collectionData,
      }
      break;
  }

  return state;
};

export default reducer;
