import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import PageHeader from 'src/components/PageHeader';
import Page from 'src/components/Page';
import ImageList from 'src/components/ImageList';
import firebase from 'src/utils/firebase';
import Chips from 'src/components/Chips';
import { connect } from 'react-redux';
import actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

const ListView = (props) => {
    const { 
        appStore,
        collectionKey,
        title,
        icon: Icon,
        fetchCollectionData 
    } = props;

    
    const classes = useStyles();
    const [galleryData, setGalleryData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const collectionItems = appStore[collectionKey];

    const getCollectionData = async () => {
        setIsLoading(true);
        await fetchCollectionData(collectionKey);
        setIsLoading(false);
    }

    const getGalleryData = async () => {
        const items = await firebase.getCollection('gallery', [
            ['collectionKey', '==', collectionKey]
        ]);

        setGalleryData(items ? items[0] : {});
    }

    useEffect(() => {
        getGalleryData();
        getCollectionData();
    }, [collectionKey]);

    return (
        <Page className={classes.root} title={title} isLoading={isLoading}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <PageHeader>{title}</PageHeader>
                    
                    <Grid item xs={12} lg={8}>
                        {collectionItems && collectionItems.map((item) => <Box key={item.title}>
                            <Box display="flex">
                                <Icon style={{ color: '#546E7A' }} />
                                <Box mr={2} />
                                <Typography>
                                    {item.title} {item.link && <Button size="small" color="primary" href={item.link.url} target="_blank">
                                        {item.link.title}
                                    </Button>}
                                </Typography>
                            </Box>
                            
                            {item.items && <Box mt={2} mb={3}>
                                <Chips items={item.items} color={item.chipColor} />
                            </Box>}

                            <Box mb={3} />
                        </Box>)}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Hidden xsDown>
                                <Box mt={12} />
                            </Hidden>
                            <img
                                alt={collectionKey}
                                src={`/static/images/thumbnails/${collectionKey}.svg`}
                                width="250"
                            />
                        </Box>
                    </Grid>

                    {galleryData && !galleryData.disabled && galleryData.images && <Grid item xs={12}>
                        <Box mb={6} />
                        <ImageList images={galleryData.images} />
                    </Grid>}
                </Grid>
            </Container>
        </Page>
    );
};

const mapStateToProps = (state) => {  
    const appStore = state.app;
    
    return {
        appStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollectionData: (key) => {
            return dispatch(actions.fetchCollectionData(key));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
