import React from 'react';

export default (props) => {

    const { command } = props;

    return <>
        ls: cannot access { command.substring(3) } : No such file or directory. <br />
        Try ls skills, ls interests, ls experiences, ls projects, ls edu, ls achievements, ls extra_activities, ls resume
    </>
}
