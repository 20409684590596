import collections from './collections';

const possibleCommands = {
    exit: [
        'exit',
        'gui',
        'exit && show gui',
        'poweroff',
        'home',
        'close',
        'off',
        'turnoff',
        'turn off'
    ],

    clear: [
        'clear',
        'site --help',
        '--help',
        'help',
        'man'
    ],

    // download resume
    resume: [
        'resume',
        'cv',
        'wget resume',
        'wget cv'
    ],

    viewResume: [
        'echo resume',
        'echo cv',
        'ls resume',
        'ls cv',
        'view cv',
        'view resume',
    ],
}

// add collection's possible commands
Object.values(collections).forEach((collection) => {
    possibleCommands[collection.name] = collection.possibleCommands;
});

export default possibleCommands;
