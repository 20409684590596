import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Chip,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardHeader,
  Tooltip
} from '@material-ui/core';
import Chips from 'src/components/Chips';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const BlogPostCard = ({ className, blog, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={<Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {blog.title}
          <Typography variant="caption">
            {blog.time_to_read}
          </Typography>
        </Box>}
        color="secondary"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {blog.thumbnail
            && <Grid item md={3}>
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                <img alt={blog.title} width={blog.thumbnail.width} src={blog.thumbnail.url} />
              </Box>
            </Grid>}
          <Grid item md={blog.thumbnail ? 9 : 12}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Typography>
                {blog.description} <a href={blog.link} target="_blank">read more</a>
              </Typography>
              <Box mt={2} />
              {blog.tags && <Chips items={blog.tags} size="small" />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

BlogPostCard.propTypes = {
  className: PropTypes.string,
  blog: PropTypes.object.isRequired
};

export default BlogPostCard;
