import React from 'react';
import GitHub from '@material-ui/icons/GitHub';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';
import GMap from '@material-ui/icons/Room';
import Facebook from '@material-ui/icons/Facebook';
import { Hidden } from '@material-ui/core';

export default () => {
    return <li className="line" style={{ display: 'flex' }}>
        send mail to &quot;a.nambissan@gmail.com&quot; 
        <Hidden xsDown>
            &nbsp;&& connect &nbsp;
            <a className="icon-link" href="/stackoverflow" target="_blank">
                <img
                    alt="stackoverflow"
                    src="/static/stackoverflow.svg"
                    style={{ width: '14px', height: '16px' }}
                />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/linkedin" target="_blank">
                <LinkedIn style={{ fontSize: '20px' }} />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/github" target="_blank">
                <GitHub style={{ fontSize: '16px' }} />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/twitter" target="_blank">
                <Twitter style={{ fontSize: '20px' }} />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/gmaps" target="_blank">
                <GMap style={{ fontSize: '20px' }} />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/instagram" target="_blank">
                <Instagram style={{ fontSize: '20px' }} />
            </a>&nbsp;&nbsp;
            <a className="icon-link" href="/fb" target="_blank">
                <Facebook style={{ fontSize: '20px' }} />
            </a>&nbsp;&nbsp;
        </Hidden>
    </li>
}
