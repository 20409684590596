import { SET_COLLECTIONS } from './actionTypes';
import firebase from '../utils/firebase';
import config from 'src/config';

const fetchCollectionData = (collectionKey) => {
    return async (dispatch, getState) => {

        const app = getState().app;
        
        if (app[collectionKey].length > 0)
            return

        const collectionData = await firebase.getCollection(collectionKey, [], config.collectionOrderBy);
        
        dispatch({
            type: SET_COLLECTIONS,
            payload: {
                collectionKey,
                collectionData
            }
        })
    }
}

export default {
    fetchCollectionData
}