import React from 'react';
import {
    makeStyles,
    ListItemText,
    Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    image: {
        height: 70,
        width: 70
    }
}));

export default ({ alignCenter }) => {

    const classes = useStyles();

    const details = {
        company: <strong>Stapto</strong>,
        designation: <span><a href="https://www.stapto.com" target="_blank">www.stapto.com</a><br /> Leisure time works <br /> (Non profit)</span>,
        imageUrl: '/static/images/stapto.png',
    }

    return <Box display="flex" justifyContent={alignCenter ? 'center' : 'flex-start'} alignItems="center">
        <Box mr={2}>
            <img
                alt="logo"
                className={classes.image}
                src={details.imageUrl}
            />
        </Box>
        <Box>
            <ListItemText
                primary={details.company}
                secondary={details.designation}
            />
        </Box>
    </Box>
}
