import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAltSharp';

export default ({ onClose }) => {
    return <Box mt={4} display="flex" flexDirection="row" justifyContent="flex-end">
        <Button style={{ marginBottom: 20 }} variant="contained" color="primary" onClick={onClose} endIcon={<ArrowRightIcon />}>
            Close terminal and view webpage
        </Button>
    </Box>
}
