import React, { useState } from 'react';
import './terminal.css';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { Hidden } from '@material-ui/core';
import firebase from '../../../utils/firebase';
import SiteHelp from './SiteHelp';
import TerminalInput from './TerminalInput';
import Loading from './Loading';
import TerminalHeader from './TerminalHeader';
import TerminalFooter from './TerminalFooter';
import Contact from './Contact';

const Terminal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [hideLoadingText, setHideLoadingText] = useState(false);
    const [terminalInput, setTerminalInput] = useState();
    const [terminalLines, setTerminalLines] = useState([]);
    const navigate = useNavigate();

    const saveCommandLog = (cmd) => {
        if (typeof cmd === 'string') firebase.createNewDocument('terminal_command_logs', { cmd, createdAt: new Date() });
    }

    const executeCommand = (command, meta) => {
        const newLine = { id: new Date().getTime(), content: command, meta };
        setTerminalLines([...terminalLines, newLine]);
        saveCommandLog(command);
    }

    const scrollToBottom = () => {
        const objDiv = document.getElementById('terminal-body');
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    const onClose = () => {
        navigate('/home/bio');
    }

    return (
        <div id="terminal">
            <div className="terminal-wrap" onClick={() => terminalInput && terminalInput.focus()}>

                <Page title="Terminal - Online Portfolio"><Hidden xsUp>.</Hidden></Page>

                <TerminalHeader onClose={onClose} />

                <ul className="terminal-body" id="terminal-body">
                    <li className="line line-1">Hi. I am ARUN D :)</li>
                    <li className="line line-1">&nbsp;</li>
                    <li className="line line-2">&nbsp;</li>
                    <Contact />
                    <li className="line">works @ &quot;<a href="https://www.westerndigital.com/" target="_blank" className="remove-link-styles">Western Digital, Bangalore</a>&quot;</li>
                    <li className="line" >leisure time works =&gt; &quot;<a href="https://www.stapto.com" target="_blank" className="remove-link-styles">Stapto | www.stapto.com</a>&quot;{' (non-profit)'}</li>
                    <li className="line">&nbsp;</li>

                    <SiteHelp executeCommand={executeCommand} />

                    {terminalLines.map((line) => <li className={line.meta && line.meta.hideDollarSymbol ? '' : 'line'} key={line.id}>
                        {line.content}
                    </li>)}

                    {isLoading ? <Loading hideLoadingText={hideLoadingText} />
                        : <TerminalInput
                            terminalInput={terminalInput}
                            scrollToBottom={scrollToBottom}
                            onClose={onClose}
                            terminalLines={terminalLines}
                            setTerminalLines={setTerminalLines}
                            setTerminalInput={setTerminalInput}
                            setIsLoading={setIsLoading}
                            executeCommand={executeCommand}
                            setHideLoadingText={setHideLoadingText}
                        />}
                </ul>

                <TerminalFooter onClose={onClose} />

            </div>
        </div>
    );
};

export default Terminal;
