import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { Work } from '@material-ui/icons';
import CurrentWork from './CurrentWork';
import Stapto from './Stapto';

export default () => {
    return <Timeline align="alternate">    
        <TimelineItem>
            <TimelineOppositeContent>
                <CurrentWork />
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary">
                    <Work />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Stapto />
            </TimelineContent>
        </TimelineItem>
    </Timeline>
}
