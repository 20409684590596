import React from 'react';
import { 
    Grid,
    Typography,
    Box,
    Divider,
} from '@material-ui/core';

export default ({ children }) => {
    return <Grid item xs={12}>
        <Typography variant="h2" color="primary">
            {children}
        </Typography>
        <Box mt={2} mb={2}>
            <Divider />
        </Box>
    </Grid>
}
