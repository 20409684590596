export default {
    hello: [
        'Online Portfolio',
        'Hello',
        'Hola',
        'Bonjour',
        'नमस्ते',
        'ഹായ്'
    ],
    collectionOrderBy: [
        ['order', 'desc']
    ],
    collections: [
        'projects',
        'experiences',
        'skills',
        'interests',
        'edu',
        'certifications',
        'sessions_conducted',
        'achievements',
        'extra_activities',
        'blog_posts',
    ]
}
