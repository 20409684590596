import { Box, Typography } from '@material-ui/core';
import { EmailOutlined } from '@material-ui/icons';
import React from 'react';

export default ({ value }) => {
    return <Box display="flex" flexDirection="row" mt={1}>
        <EmailOutlined />
        <Box mr={2} />
        <Typography>
            {value}
        </Typography>
    </Box>
}
