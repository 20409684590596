import React, { useEffect, useState } from 'react';
import config from 'src/config';
import firebase from 'src/utils/firebase';
import possibleCommands from './possibleCommands';
import CommandNotFound from './CommandNotFound';
import LsError from './LsError';
import collectionData from './collections';

export default (props) => {

    const { 
        setTerminalInput,
        setTerminalLines,
        terminalLines,
        setIsLoading,
        executeCommand,
        scrollToBottom,
        onClose,
        setHideLoadingText,
        terminalInput
    } = props;

    const [inputText, setInputText] = useState('');
    const [terminalHistory, setTerminalHistory] = useState(JSON.parse(localStorage.terminalHistory || '[]'));
    const [isSudoActive, setSudoActive] = useState(false);
    const [historyIndex, setHistoryIndex] = useState(0);

    const getCollectionData = async (key) => {
        setIsLoading(true);
        
        const items = await firebase.getCollection(key, [], config.collectionOrderBy);

        setIsLoading(false);

        executeCommand(collectionData[key].render(items), { silent: true, hideDollarSymbol: true });
    }

    const commandMatchCheck = (key, command) => possibleCommands[key] && possibleCommands[key].indexOf(command) >= 0;

    const downloadResume = () => {
        executeCommand(<span>downloading resume...</span>, { hideDollarSymbol: true });
        window.open('cv');
    }

    const viewResume = () => {
        executeCommand(<span>opening resume...</span>, { hideDollarSymbol: true });
        window.open('cv/view');
    }

    const checkIfUnAutherizedCommand = (cmd) => cmd === 'mkdir' || cmd.substring(0, 6) === 'mkdir ' 
        || cmd === 'chmod' || cmd.substring(0, 6) === 'chmod ' 
        || cmd === 'chown' || cmd.substring(0, 6) === 'chown '
        || cmd === 'rmdir' || cmd.substring(0, 6) === 'rmdir '
        || cmd === 'cp' || cmd.substring(0, 3) === 'cp '
        || cmd === 'diff' || cmd.substring(0, 5) === 'diff '
        || cmd === 'more' || cmd.substring(0, 5) === 'more '
        || cmd === 'mv' || cmd.substring(0, 3) === 'mv '
        || cmd === 'rm' || cmd.substring(0, 3) === 'rm '
        || cmd === 'pico' || cmd.substring(0, 5) === 'pico '
        || cmd === 'nano' || cmd.substring(0, 5) === 'nano '
        || cmd === 'ps' || cmd.substring(0, 3) === 'ps '
        || cmd === 'whereis' || cmd.substring(0, 8) === 'whereis '
        || cmd === 'mount' || cmd.substring(0, 6) === 'mount '
        || cmd === 'unmount' || cmd.substring(0, 8) === 'unmount '
        || cmd === 'zip' || cmd.substring(0, 4) === 'zip '
        || cmd === 'unzip' || cmd.substring(0, 6) === 'unzip '
        || cmd === 'df' || cmd.substring(0, 3) === 'df '
        || cmd === 'apt' || cmd.substring(0, 4) === 'apt '
        || cmd === 'apt-get' || cmd.substring(0, 8) === 'apt-get '
        || cmd === 'cat' || cmd.substring(0, 4) === 'cat ';

    const terminalSleep = (command) => {
        let seconds = 20;
        const extractedSeconds = parseInt(command.replace('spinner sleep ', ''), 10);
        
        if (!isNaN(extractedSeconds)) {
            seconds = extractedSeconds;
        }

        setHideLoadingText(true);
        setIsLoading(true);
        executeCommand(<br />, { hideDollarSymbol: true });
        scrollToBottom();

        setTimeout(() => {
            setIsLoading(false);
            setHideLoadingText(false);
            terminalInput.focus();
        }, seconds * 1000);
    }

    const processLsAndEchoCommand = (command, type) => {
        const key = command.replace(`${type} `, '');

        if (commandMatchCheck(key, command)) {
            getCollectionData(key);
        } else if (type === 'ls') {
            executeCommand(<LsError command={command} />, { hideDollarSymbol: true });
        } else {
            executeCommand(command.substring(5), { silent: true, hideDollarSymbol: true });
        }
    }

    useEffect(() => {
        const lastCommand = terminalLines[terminalLines.length - 1];
        scrollToBottom();
        if (!lastCommand) return;
        if (!(typeof lastCommand.content === 'string')) return;
        if (lastCommand.meta && lastCommand.meta.silent) return;

        if (isSudoActive) {
            setSudoActive(false);
            executeCommand(<span>&nbsp;&nbsp;sudo: incorrect password attempt</span>, { silent: true, hideDollarSymbol: true });
        } else if (commandMatchCheck('clear', lastCommand.content)) {
            setTerminalLines([]);
        } else if (commandMatchCheck('exit', lastCommand.content)) {
            onClose();
        } else if (commandMatchCheck('resume', lastCommand.content)) {
            downloadResume();
        } else if (commandMatchCheck('viewResume', lastCommand.content)) {
            viewResume();
        } else if (lastCommand.content.indexOf('ls ') === 0) {
            processLsAndEchoCommand(lastCommand.content, 'ls');
        } else if (lastCommand.content.indexOf('echo ') === 0) {
            processLsAndEchoCommand(lastCommand.content, 'echo');
        } else if (lastCommand.content.indexOf('cd') === 0) {
            executeCommand(`bash: cd: /${lastCommand.content.substring(3)}: No such file or directory`, { silent: true, hideDollarSymbol: true });
        } else if (lastCommand.content === 'pwd') {
            executeCommand('/home/arund.in', { silent: true, hideDollarSymbol: true });
        } else if (lastCommand.content === 'sudo' || lastCommand.content.indexOf('sudo ') === 0) {
            executeCommand('[sudo] password for arun:', { silent: true, hideDollarSymbol: true });
            setSudoActive(true);
        } else if (lastCommand.content === 'date') {
            executeCommand(new Date().toString(), { silent: true, hideDollarSymbol: true });
        } else if (checkIfUnAutherizedCommand(lastCommand.content)) {
            executeCommand('Permission denied', { silent: true, hideDollarSymbol: true });
        } else if (lastCommand.content.indexOf('spinner sleep ') >= 0) {
            terminalSleep(lastCommand.content);
        } else {
            executeCommand(<CommandNotFound command={lastCommand.content} onClose={onClose} />, { hideDollarSymbol: true });
        }
    }, [terminalLines]);

    const setTextFromHistory = (direction) => {
        if (direction === 'up') {
            if (inputText === '') {
                setHistoryIndex(terminalHistory.length - 1);
            } else if (historyIndex > 0) {
                setHistoryIndex(historyIndex - 1);
            }
            
            setInputText(terminalHistory[historyIndex]);
        } else if (direction === 'down' && inputText !== '') {
            if (historyIndex < (terminalHistory.length - 1)) {
                setHistoryIndex(historyIndex + 1);
                setInputText(terminalHistory[historyIndex]);
            }
        }
    }

    const updateHistory = () => {
        const newHistoryArray = [...terminalHistory, inputText];
        setTerminalHistory(newHistoryArray);
        localStorage.terminalHistory = JSON.stringify(newHistoryArray);
    }

    const onEnter = () => {
        if (!isSudoActive && inputText === '') return;
        executeCommand(inputText);
        updateHistory();
        setInputText('');
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            onEnter();
        } else if (event.key === 'ArrowUp') {
            setTextFromHistory('up');
        } else if (event.key === 'ArrowDown') {
            setTextFromHistory('down');
        } else if (event.ctrlKey) {
            if (event.key.toLowerCase() === 'c') {
                executeCommand('^C', { hideDollarSymbol: true, silent: true });
            } else if (event.key.toLowerCase() === 'z') {
                executeCommand('^Z', { hideDollarSymbol: true, silent: true });
            }
        }
    }

    return <li className="input-line line">
        <input 
            autoFocus
            type="text"
            className="terminal-input"
            ref={(input) => setTerminalInput(input)}
            value={inputText}
            onChange={(e) => !isSudoActive && setInputText(e.target.value)}
            onKeyUp={onKeyPress}
        />
    </li>
}
