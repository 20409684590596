import { Box } from '@material-ui/core';
import React from 'react';

export default (props) => {

    const { items } = props;

    return <>
        <Box mb={1} />
        <strong>EDUCATION</strong>
        <ul className="ul-remove-bullets">
            {items && items.map((item) => <li className="no-bullet" style={{ marginBottom: 10 }} key={item.id}>
                {'>'} <strong>{item.title}</strong>{item.url && ` | ${item.url}`}
                {item.details && item.details.map((detail) => <span key={detail}>
                    <br />&nbsp;&nbsp;- {detail}
                </span>)}
            </li>)}
        </ul>
    </>
}
