import React from 'react';
import Projects from './collections/Projects';
import Experiences from './collections/Experiences';
import Edu from './collections/Edu';
import Skills from './collections/Skills';
import CommonVariant from './collections/CommonVariant';

export default {
    projects: {
        name: 'projects',
        render: (items) => <Projects items={items} />,
        possibleCommands: [
            'ls projects',
            'echo projects',
            'ls project',
            'echo project'
        ]
    },
    experiences: {
        name: 'experiences',
        render: (items) => <Experiences items={items} />,
        possibleCommands: [
            'ls experiences',
            'echo experiences',
            'ls experience',
            'echo experience',
            'echo job',
            'ls job'
        ]
    },
    edu: {
        name: 'edu',
        render: (items) => <Edu items={items} />,
        possibleCommands: [
            'ls edu',
            'echo edu',
            'ls education',
            'echo education'
        ]
    },
    skills: {
        name: 'skills',
        render: (items) => <Skills items={items} />,
        possibleCommands: [
            'ls skills',
            'echo skills',
            'ls skills',
            'ls skill',
            'ls technical',
            'ls code',
            'ls technical_skills'
        ]
    },
    interests: {
        name: 'interests',
        render: (items) => <CommonVariant items={items} header="INTERESTS" />,
        possibleCommands: [
            'ls interests',
            'ls interest',
            'echo interests',
            'echo interest'
        ]
    },
    achievements: {
        name: 'achievements',
        render: (items) => <CommonVariant items={items} header="ACHIEVEMENTS" />,
        possibleCommands: [
            'ls achievements',
            'echo achievements',
            'ls achievement',
            'echo achievement'
        ]
    },
    extra_activities: {
        name: 'extra_activities',
        render: (items) => <CommonVariant items={items} header="EXTRA ACTIVITIES" />,
        possibleCommands: [
            'ls extra_activities',
            'echo extra_activities',
            'ls activities',
            'echo activities'
        ]
    },
    certifications: {
        name: 'certifications',
        render: (items) => <CommonVariant items={items} header="CERTIFICATIONS" />,
        possibleCommands: [
            'ls certifications',
            'echo certifications',
            'ls courses',
            'echo courses',
            'ls trainings',
            'echo trainings',
            'ls events',
            'echo events',
        ]
    },
    sessions_conducted: {
        name: 'sessions_conducted',
        render: (items) => <CommonVariant items={items} header="SESSIONS CONDUCTED" />,
        possibleCommands: [
            'ls sessions_conducted',
            'echo sessions_conducted',
            'ls sessions',
            'echo sessions',
            'ls workshops',
            'echo workshops',
        ]
    }
};
