import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    makeStyles,
    Button,
    Card,
    CardContent,
    TextField,
    Hidden,
} from '@material-ui/core';
import PageHeader from 'src/components/PageHeader';
import Page from 'src/components/Page';
import SuccessMessage from 'src/components/SuccessMessage';
import firebase from 'src/utils/firebase';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

const fields = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

const MessageView = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState(fields);
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };
    
    const onSubmit = async (e) => {
        e.preventDefault();
    
        setIsLoading(true);
        await firebase.createNewDocument('messages', { ...values, createdAt: new Date() });
        setIsLoading(false);
        setOpen(true);
        setValues(fields);
    }

    return (
        <Page className={classes.root} title="Leave a message" isLoading={isLoading}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <PageHeader>Leave a message</PageHeader>
                    
                    <Grid item lg={8} xs={12}>
                        <form autoComplete="off" onSubmit={onSubmit}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <SuccessMessage open={open} setOpen={setOpen} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                name="name"
                                                size="small"
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item md={6} xs={12}></Grid>
                                        </Hidden>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email Address"
                                                name="email"
                                                size="small"
                                                onChange={handleChange}
                                                value={values.email}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Phone Number"
                                                name="phone"
                                                size="small"
                                                onChange={handleChange}
                                                type="number"
                                                value={values.phone}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField
                                                fullWidth
                                                label="Message"
                                                size="small"
                                                placeholder="Start writing your message..."
                                                name="message"
                                                onChange={handleChange}
                                                required
                                                value={values.message}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button type="submit" color="primary" variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Grid>
                
                    <Grid item lg={4} xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <img
                                alt="Leave a message"
                                src="/static/images/thumbnails/message.svg"
                                width="250"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default MessageView;
