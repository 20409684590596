import React from 'react';
import { Box } from '@material-ui/core';
import CurrentWork from './CurrentWork';
import Stapto from './Stapto';

export default () => {
    return <Box>
        <CurrentWork alignCenter />
        <Box mb={3} />
        <Stapto alignCenter />
    </Box>
}
