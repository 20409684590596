import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    Divider,
    Hidden,
} from '@material-ui/core';
import PageHeader from 'src/components/PageHeader';
import Page from 'src/components/Page';
import config from 'src/config';
import firebase from '../../../utils/firebase';
import ProjectCard from './ProjectCard';
import { connect } from 'react-redux';
import actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

const ProjectsView = ({ fetchCollectionData, projects }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const getCollectionData = async (key) => {
        setIsLoading(true);
        
        await fetchCollectionData(key);

        setIsLoading(false);
    }

    useEffect(() => {
        getCollectionData('projects');
    }, []);

    return (
        <Page className={classes.root} title="Projects" isLoading={isLoading}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <PageHeader>Projects</PageHeader>
                    <Grid item md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            {projects.map((project) => <Grid item xs={12} key={project.docId}>
                                <ProjectCard project={project} />
                            </Grid>)}
                        </Grid>
                    </Grid>

                    <Grid item md={4} sm={12} xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" mt={4} width="100%" height="100%">
                            <img
                                alt="projects"
                                src="/static/images/thumbnails/projects.svg"
                                width="200"
                            />

                            <Hidden smDown>
                                <img
                                    alt="projects"
                                    src="/static/images/thumbnails/projects_4.svg"
                                    width="200"
                                />

                                <img
                                    alt="projects"
                                    src="/static/images/thumbnails/projects_3.svg"
                                    width="200"
                                />

                                <img
                                    alt="projects"
                                    src="/static/images/thumbnails/projects_2.svg"
                                    width="200"
                                />
                            </Hidden>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

const mapStateToProps = (state) => {  
    const { projects } = state.app;
    
    return {
        projects
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollectionData: (key) => {
            return dispatch(actions.fetchCollectionData(key));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView);
