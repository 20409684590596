import React from 'react';
import {
    Box,
    Chip,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

export default ({ items, color, size }) => {
    const classes = useStyles();

    return items ? <Box className={classes.chipContainer}>
        {items.map((chip) => <Chip key={chip} label={chip} color={color} size={size} />)}
    </Box> : <></>
}
