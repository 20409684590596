import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import firebase from 'src/utils/firebase';

export default () => {

    const params = useParams();
    const navigate = useNavigate();

    const redirectToShortUrl = async () => {
        console.log(params.from)
        const url = await firebase.getDocument('short_urls', params.from);

        if (url && url.to) {
            location.href = url.to;
        } else {
            navigate('404');
        }
    }

    useEffect(() => {
        redirectToShortUrl();
    }, []);

    return <Page isLoading title="Redirecting short URL">
        &nbsp;
    </Page>
}
