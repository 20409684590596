import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import {
  Person,
  Category,
  Work,
  Build,
  Favorite,
  School,
  VerifiedUser,
  CameraEnhance,
  Chat,
  Create
} from '@material-ui/icons';
import NavItem from './NavItem';

const items = [
  {
    href: '/home/bio',
    icon: Person,
    title: 'Bio'
  },
  {
    href: '/home/projects',
    icon: Category,
    title: 'Projects'
  },
  {
    href: '/home/experiences',
    icon: Work,
    title: 'Experiences'
  },
  {
    href: '/home/skills',
    icon: Build,
    title: 'Skills'
  },
  {
    href: '/home/interests',
    icon: Favorite,
    title: 'Interests'
  },
  {
    href: '/home/education',
    icon: School,
    title: 'Education'
  },
  {
    href: '/home/certifications',
    icon: VerifiedUser,
    title: 'Certifications & Trainings'
  },
  {
    href: '/home/sessions-conducted',
    icon: () => <img
      alt="host"
      src="/static/images/icons/host.svg"
      style={{ width: '22px', height: '22px', marginRight: 10 }}
    />,
    title: 'Sessions Conducted'
  },
  {
    href: '/home/blog',
    icon: Create,
    title: 'Blog'
  },
  {
    href: '/home/achievements',
    icon: () => <img
      alt="medal"
      src="/static/images/icons/medal.svg"
      style={{ width: '22px', height: '22px', marginRight: 10 }}
    />,
    title: 'Achievements'
  },
  {
    href: '/home/extra-activities',
    icon: CameraEnhance,
    title: 'Extra Activities'
  },
  {
    href: '/home/message',
    icon: Chat,
    title: 'Leave a Message'
  },
  {
    href: '/back-to-terminal',
    icon: () => <img
      alt="terminal"
      src="/static/images/icons/terminal.svg"
      style={{ width: '22px', height: '22px', marginRight: 10 }}
    />,
    title: 'Back To Terminal'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    // top: 64,
    // height: 'calc(100% - 64px)'
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
