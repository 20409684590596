import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

const Page = forwardRef(({
  children,
  title = '',
  isLoading,
  ...rest
}, ref) => {
  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{`${title} | Arun D`}</title>
      </Helmet>
      {isLoading ? <Box display="flex" justifyContent="center" alignItems="center" height="40vh">
        <CircularProgress />
      </Box> : children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
