import { Box } from '@material-ui/core';
import React from 'react';

export default (props) => {

    const { items } = props;

    return <>
        <Box mb={1} />
        <strong>SKILLS</strong>
        <ul className="ul-remove-bullets">
            {items && items.map((item) => <li className="no-bullet" style={{ marginBottom: 10 }} key={item.id}>
                {'>'} <strong>{item.title}</strong><br />
                {item.items && item.items.map((skill, index) => <span key={skill}>
                    {skill}{index < item.items.length - 1 && ' | '}
                </span>)}
            </li>)}
        </ul>
    </>
}
