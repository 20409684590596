import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  Build,
  CameraEnhance,
  Favorite,
  School,
  VerifiedUser,
  Work,
  Create
} from '@material-ui/icons';
import DashboardLayout from 'src/layouts/DashboardLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import TerminalView from './views/terminal/TerminalView';
import MainLayout from './layouts/MainLayout';
import NavigateToExternalUrl from './components/NavigateToExternalUrl';
import BioView from './views/bio/BioView';
import ProjectsView from './views/projects/ProjectsView';
import BlogView from './views/blog/BlogView';
import ListView from './views/list/ListView';
import TimelineView from './views/timeline/TimelineView';
import MessageView from './views/message/MessageView';
import ShortUrlView from './views/shorturl/ShortUrlView';
import Medal from './components/icons/Medal';
import Host from './components/icons/Host';

const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '', element: <TerminalView /> },
    ]
  },
  {
    path: 'home',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="/home/bio" /> },
      { path: 'bio', element: <BioView /> },
      { path: 'projects', element: <ProjectsView /> },
      { path: 'experiences', element: <TimelineView title="Experiences" collectionKey="experiences" icon={Work} /> },
      { path: 'skills', element: <ListView title="Skills" collectionKey="skills" icon={Build} /> },
      { path: 'interests', element: <ListView title="Interests" collectionKey="interests" icon={Favorite} /> },
      { path: 'education', element: <TimelineView title="Education" collectionKey="edu" icon={School} /> },
      { path: 'certifications', element: <ListView title="Certifications" collectionKey="certifications" icon={VerifiedUser} /> },
      { path: 'sessions-conducted', element: <ListView title="Sessions Conducted" collectionKey="sessions_conducted" icon={Host} /> },
      { path: 'blog', element: <BlogView /> },
      { path: 'achievements', element: <ListView title="Achievements" collectionKey="achievements" icon={Medal} /> },
      { path: 'extra-activities', element: <ListView title="Extra Activities" collectionKey="extra_activities" icon={CameraEnhance} /> },
      { path: 'message', element: <MessageView /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  },
  {
    path: 'back-to-terminal', element: <Navigate to="/" />
  },
  {
    path: 's/:from',
    element: <ShortUrlView />
  },
  {
    path: 'short/:from',
    element: <ShortUrlView />
  },
  {
    path: '404',
    element: <NotFoundView />
  },
  {
    path: 'resume',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/d/1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'resume/download',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/export?format=pdf&id=1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'resume/view',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/d/1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'cv',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/d/1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'cv/download',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/export?format=pdf&id=1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'cv/view',
    element: <NavigateToExternalUrl url="https://docs.google.com/document/d/1sBbsahT5kU_m1MQasASe7xwXhZBrVPcFSnpURRRTzvg" />
  },
  {
    path: 'gmaps',
    element: <NavigateToExternalUrl url="https://www.google.co.in/maps/contrib/117577359342529100132/contribute" />
  },
  {
    path: 'linkedin',
    element: <NavigateToExternalUrl url="https://www.linkedin.com/in/arund-in" />
  },
  {
    path: 'github',
    element: <NavigateToExternalUrl url="https://github.com/arunnambissan" />
  },
  {
    path: 'twitter',
    element: <NavigateToExternalUrl url="https://twitter.com/arund_in" />
  },
  {
    path: 'stackoverflow',
    element: <NavigateToExternalUrl url="https://stackoverflow.com/users/3898364/arun-d-nambissan" />
  },
  {
    path: 'instagram',
    element: <NavigateToExternalUrl url="https://instagram.com/arund.in" />
  },
  {
    path: 'insta',
    element: <NavigateToExternalUrl url="https://instagram.com/arund.in" />
  },
  {
    path: 'fb',
    element: <NavigateToExternalUrl url="https://facebook.com/hi.arund.in" />
  },
  {
    path: 'facebook',
    element: <NavigateToExternalUrl url="https://facebook.com/hi.arund.in" />
  },
  {
    path: '*',
    element: <Navigate to="/404" />
  }
];

export default routes;
