import React from 'react';
import {
    makeStyles,
    ListItemText,
    Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    image: {
        height: 70,
        width: 70
    }
}));

export default ({ alignCenter }) => {

    const classes = useStyles();

    const details = {
        company: <strong>Western Digital</strong>,
        designation: <span>Staff Engineer<br />Software Development Engineering Apps<br />(April 2022 - Present)</span>,
        imageUrl: '/static/images/wd.png',
    }

    return <Box display="flex" justifyContent={alignCenter ? 'center' : 'flex-end'} alignItems="center">
        <Box mr={2}>
            <ListItemText
                primary={details.company}
                secondary={details.designation}
            />
        </Box>
        <Box>
            <img
                alt="logo"
                className={classes.image}
                src={details.imageUrl}
            />
        </Box>
    </Box>
}
